<template>
  <v-app>
    <nav></nav>
    <div class="container-contact100">
      <div class="contact100-map"></div>

      <div class="wrap-contact100" style="padding: 0 !important">
        <div
          class="
            pa-2 pa-md-4
            flex-grow-1
            align-center
            justify-center
            flex-column
          "
        >
          <v-main>
            <router-view />
          </v-main>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      appTitle: "Awesome App",
      sidebar: false,
    };
  },
};
</script>

<style scoped>
.container-contact100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  position: relative;
  z-index: 1;
}
.container-contact100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(48, 85, 153);
  /*background: -webkit-linear-gradient(left, rgba(0,168,255,0.5), rgba(185,0,255,0.5));
  background: -o-linear-gradient(left, rgba(0,168,255,0.5), rgba(185,0,255,0.5));
  background: -moz-linear-gradient(left, rgba(0,168,255,0.5), rgba(185,0,255,0.5));
  background: linear-gradient(left, rgba(0,168,255,0.5), rgba(185,0,255,0.5));*/
  pointer-events: none;
}
.contact100-map {
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.wrap-contact100 {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  /*padding: 72px 150px 25px 150px;*/

  box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
}
</style>
