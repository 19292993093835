const agentMutation = {
  SIGN_IN_AGENT: (state, response) => {
    state.loggedInAgent = response;
    state.loggedInAgent.isAuthenticated = true;
    console.log("login success", response);
  },
  SET_LOGGED_IN_USER: (state, user) => {
    console.log("SET_LOGGED_IN_USER", user);

    state.loggedInAgentInfo = user;
    state.loggedInAgentInfo.isAuthenticated = true;
  },
  SET_CAR_BRANDS: (state, brandList) => {
    state.brands = brandList;
  },
  SET_RELATIVE_CAR_MODEL: (state, modelList) => {
    state.models = modelList;
  },
  CLEAR_RELATIVE_CAR_MODEL: (state) => {
    state.models = [];
  },
  SET_VALID_YEARS: (state, yearList) => {
    state.years = yearList;
  },
  CLEAR_VALID_YEARS: (state) => {
    state.years = [];
  },
  SET_VALID_SUB_MODELS: (state, subModelList) => {
    state.subModelList = subModelList;
  },
  CLEAR_VALID_SUB_MODELS: (state) => {
    state.subModelList = [];
  },
  SET_VALID_PRICE: (state, price) => {
    state.price = parseFloat(price[0]);
    state.price_150_percent = price[0] * 1.5;
  },
  CLEAR_VALID_PRICE: (state) => {
    state.price = null || "โปรดเลือกรุ่นรถของคุณ";
    state.price_150_percent = null || "โปรดเลือกรุ่นรถของคุณ";
  },
  SET_NEW_LEAD: (state, lead) => {
    state.new_lead = lead;
  },
  CLEAR_NEW_LEAD: (state) => {
    state.new_lead = null;
  },
  DO_NOTHING: () => {
    console.log("do nothing");
  },
  LOGOUT_USER: (state) => {
    state.loggedInAgent.isAuthenticated = false;
    state.loggedInAgent.token = null;
  },
};

export default agentMutation;
