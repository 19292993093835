import axios from "axios";
import moment from "moment";

const publicInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10000,
});

const agentAction = {
  signIn: ({ commit }, message) => {
    return new Promise((resolve, reject) => {
      publicInstance
        .post("/login/agent/", {
          username: message.username,
          password: message.password,
        })
        .then((response) => {
          commit("SIGN_IN_AGENT", response.data.response);
          resolve(response.data.response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchValidBrands: ({ commit }) => {
    return new Promise((resolve, reject) => {
      publicInstance
        .get("/api/car/p/brand")
        .then((response) => {
          commit("SET_CAR_BRANDS", response.data.brands);
          resolve(response.data.brands);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchValidYear: ({ commit }, car) => {
    return new Promise((resolve, reject) => {
      publicInstance
        .get(`/api/car/p/valid_year?brand=${car.brand}&model=${car.model}`)
        .then((response) => {
          console.log("fetchValidYear", response);
          commit("SET_VALID_YEARS", response.data.years);
          resolve(response.data.years);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchValidSubModel: ({ commit }, car) => {
    return new Promise((resolve, reject) => {
      publicInstance
        .get(
          `/api/car/p/valid_sub_model?brand=${car.brand}&model=${car.model}&year=${car.year}`
        )
        .then((response) => {
          console.log("fetchValidSubModel", response);
          commit("SET_VALID_SUB_MODELS", response.data.sub_models);
          resolve(response.data.sub_models);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchValidPrice: ({ commit }, car) => {
    return new Promise((resolve, reject) => {
      publicInstance
        .get("/api/car/p/valid_price", {
          params: {
            brand: car.brand,
            model: car.model,
            year: car.year,
            sub_model: car.sub_model,
          },
        })
        .then((response) => {
          console.log("fetchValidPrice", response);
          commit("SET_VALID_PRICE", response.data.price);
          resolve(response.data.price);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchRelatedModel: ({ commit }, obj) => {
    const config = {
      url: "",
      method: "get",
      data: {},
    };

    if (obj.car_brand && obj.car_model) {
      config.url = `api/car/get_related_car_brand/?car_brand=${obj.car_brand}&car_model=${obj.car_model}`;
    } else if (obj.car_brand && !obj.car_model) {
      config.url = `api/car/get_related_car_brand/?car_brand=${obj.car_brand}`;
    } else if (!obj.car_brand && obj.car_model) {
      config.url = `api/car/get_related_car_brand/?car_model=${obj.car_model}`;
    } else {
      throw new Error("Null Inputs");
    }

    return axios
      .request(config)
      .then((resp) => {
        console.log(
          "[CASES][Actions]fetchRelativeCarInformation Response:",
          resp.data.response
        );
        commit("SET_RELATIVE_CAR_MODEL", resp.data.response);

        /* Return */
        return Promise.resolve(resp.data.response);
      })
      .catch((err) => {
        console.log("[CASES][Actions]fetchRelativeCarInformation error:", err);

        return Promise.reject(err);
      });
  },
  fetchUserProfile: ({ commit }) => {
    console.log("[fetchUserProfile]");

    return new Promise((resolve, reject) => {
      axios
        .get("api/users/profile/agent/")
        .then((resp) => {
          console.log("fetchUserProfile Response:", resp);
          commit("SET_LOGGED_IN_USER", resp.data.response);

          resolve(resp.data.response);
        })
        .catch((err) => {
          console.log("fetchUserProfile error:", err);
          if (axios.isCancel(err)) {
            console.log("fetchUserProfile Request canceled", err.message);
          }
          reject(err);
        });
    });
  },
  createNewRecord: ({ commit }, lead) => {
    console.log("lead", lead);

    return new Promise((resolve, reject) => {
      axios
        .post("/api/leads/zoho/create/", {
          data: {
            Admin_Notes: lead.contact_time,
            Age: lead.age,
            Assigned_Admin: lead.zoho_id, // Agent id
            Car_Brand: lead.brand,
            Car_Insurance_Expiration: lead.insurance_expiration,
            Car_Insurance_Category: lead.insurance_category,
            Car_Model: lead.model,
            Car_Year: lead.year,
            Customer_Name: {
              first_name: lead.first_name,
              last_name: lead.last_name,
            },
            Channel: "Agent",
            Credit_Bureau_Status: lead.black_list,
            Customer_career: lead.career,
            Date_field1: moment().format("D MMM YYYY"),
            Gender: lead.gender,
            Installment_Left: lead.installment_left,
            Lead_Form_ID: lead.Lead_Form_ID,
            Lead_Type: "Refinance",
            Loan_Paid: lead.loan_paid,
            Loan_Payment_Status: lead.loan_payment_status,
            Market_Price: lead.car_price,
            Monthly_Loan_Payment: lead.Monthly_Loan_Payment,
            Phone_1: lead.phone1.replace(/[\u200B-\u200D\uFEFF]/g, ""),
            Phone_2: lead.phone2.replace(/[\u200B-\u200D\uFEFF]/g, ""),
            Province: lead.province,
            Sub_Model: lead.sub_model,
            Surname: lead.last_name,
            ZipCode: lead.zipcode,
            LinkCarBookPage4: {
              title: lead.LinkCarBookPage4.zoho,
              url: lead.LinkCarBookPage4.signed_url,
              value: lead.LinkCarBookPage4.path,
            },
            LinkCarLicense: {
              title: lead.LinkCarLicense.zoho,
              url: lead.LinkCarLicense.signed_url,
              value: lead.LinkCarLicense.path,
            },
            LinkCarInsurance: {
              title: lead.LinkCarInsurance.zoho,
              url: lead.LinkCarInsurance.signed_url,
              value: lead.LinkCarInsurance.path,
            },
            LinkNationalId: {
              title: lead.LinkNationalId.zoho,
              url: lead.LinkNationalId.signed_url,
              value: lead.LinkNationalId.path,
            },
            LinkFace: {
              title: lead.LinkFace.zoho,
              url: lead.LinkFace.signed_url,
              value: lead.LinkFace.path,
            },
            ProductInterests: lead.interests,
          },
        })
        .then((response) => {
          console.log("createNewRecord", response);

          commit("SET_NEW_LEAD", response);
          resolve(response.data.response.detail);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadMultipleFiles: ({ commit }, files) => {
    let formData = new FormData();

    console.log("check files", files);
    for (const i in files) {
      if (files[i].img) {
        const file = new File(
          [files[i].img],
          files[i].zoho + "." + files[i].img.name.split(".").pop(),
          {
            type: "image/png",
          }
        );
        formData.append("file", file);
      }
    }
    console.log("file", files, formData);
    commit("DO_NOTHING");
    return axios.post("/api/leads/zoho/uploadf/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  changeUserPassword: ({ commit }, obj) => {
    commit("DO_NOTHING");
    return axios.put("api/users/change_pass", {
      old_password: obj.oldPassword,
      new_password: obj.newPassword,
    });
  },
  logoutAgent: ({ commit }) => {
    // Clear vuex session storage
    window.sessionStorage.clear();
    window.sessionStorage.removeItem("vuex");
    console.log("logging out, remove localStorage");
    commit("LOGOUT_USER");
  },
  updateAgentInfo: async ({ commit }, user) => {
    console.log("updateAgentInfo", user);
    try {
      const resp = await axios.put("api/users/", {
        id: user.id,
        username: user.user.username, // However user should not be able to edit username
        first_name: user.user.first_name,
        last_name: user.user.last_name,
        tel: user.tel,
        address: user.address,
        line_user_ID: user.line_user_ID,
        th_firstname: user.th_firstname,
        th_lastname: user.th_lastname,
      });

      console.log("Response:", resp);
      commit("DO_NOTHING");

      return resp;
    } catch (err) {
      console.log("updateAgentInfo error:", err);

      return err;
    }
  },
};

export default agentAction;
